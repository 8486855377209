/**
 * This iterable constants object is used to
 * map our internal data fields to Iterable.
 *
 * e.g. our internal `organization.name` gets mapped as `organization` (lowercase)
 *
 * @type {{ORGANIZATION: string, PATIENT_ID: string, EMAIL: string, LAST_NAME: string, FIRST_NAME: string, ENROLLMENT_DATE: string}}
 */
const iterablePatientFields = {
  DEVICE_TOKEN: 'Device Token',
  EMAIL: 'email',
  ENROLLMENT_DATE: 'Enrollment Date',
  ENROLLMENT_STATUS: 'Enrollment Status',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  LAST_LOGGED_FOOD: 'lastLoggedFood',
  ORGANIZATION: 'organization',
  PATIENT_ID: 'userId',
  PATIENT_STATUS: 'Status',
  PHONE_NUMBER: 'phoneNumber',
  PROGRAM_NAME: 'Program Name',
  GROUPS: 'groups',
  PROVIDERS: 'Providers',
  PROVIDER_FIRST_NAME: 'Provider First Name',
  PROVIDER_LAST_NAME: 'Provider Last Name',
  LAST_WEIGHT_LOGGED: 'lastWeightLogged',
  USER_TYPE: 'userType',
  LAST_COMPLETED_APPOINTMENT: 'lastCompletedAppointment',
  NEXT_SCHEDULED_APPOINTMENT: 'nextScheduledAppointment',
  LAST_NO_SHOW_APPOINTMENT_DATE: 'lastNoShowAppointmentDate',
  LAST_NO_SHOW_APPOINTMENT_DATE_ONLY: 'lastNoShowAppointmentDateOnly',
};

const iterableProviderFields = {
  PROVIDER_ID: 'userId',
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  ORGANIZATION: 'organization',
  PROVIDER_ZOOM_LINK: 'providerZoomLink',
  USER_TYPE: 'userType',
};

const iterableIHAdminFields = {
  ADMIN_ID: 'userId',
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  USER_TYPE: 'userType',
};

const iterableOrgAdminFields = {
  ORG_ADMIN_ID: 'userId',
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  ORGANIZATION: 'organization',
  USER_TYPE: 'userType',
};
/**
 * Iterable reserved Data fields
 *
 * @type {{DATA_FIELDS: string, CREATE_NEW_FIELDS: string}}
 */
const iterableAPIFields = {
  CREATE_NEW_FIELDS: 'createNewFields',
  DATA_FIELDS: 'dataFields',
};

const eventNames = {
  PATIENT_WELCOME_EMAIL: 'welcomeEmail',
  PROVIDER_WELCOME_EMAIL: 'welcomeEmailProvider',
  APPOINTMENT_SCHEDULED: 'appointmentScheduled',
  UPCOMING_APPOINTMENT_REMINDER: 'upcomingAppointmentReminder',
  PATIENT_REGISTRATION_COMPLETED: 'patientRegistrationCompleted',
  PROVIDER_REGISTRATION_COMPLETED: 'providerRegistrationCompleted',
  CONSENT_COMPLETED: 'consentCompleted',
  APPOINTMENT_CANCELED: 'appointmentCancelled',
  APPOINTMENT_FOLLOW_UP: 'appointmentFollowup',
  APPOINTMENT_NO_SHOW: 'appointmentNoShow',
  APPOINTMENT_RESCHEDULED: 'appointmentRescheduled',
  MESSAGE_RECEIVED_FROM_PROVIDER: 'messageReceivedFromProvider',
  PATIENT_PASSWORD_CHANGED: 'patientPasswordChanged',
  PATIENT_EMAIL_UPDATED: 'patientEmailUpdated',
  PATIENT_EMAIL_CHANGED: 'patientEmailChange',
  PATIENT_EMAIL_CHANGE_COMPLETED: 'patientEmailChangeCompleted',
  PATIENT_CONFIRM_EMAIL: 'patientConfirmEmail',
  NEW_PATIENT_ASSIGNED: 'newPatientAssigned',
  MESSAGE_RECEIVED_FROM_PATIENT: 'messageReceivedFromPatient',
  PASSWORD_RESET: 'passwordReset',
  AUTHENTICATION_CODE: 'authenticationCode',
  HIE_COMPLETED: 'hieCompleted',
  APPOINTMENT_CONFIRMED: 'appointmentConfirmed',
  ORGANIZATION_WELCOME_EMAIL: 'organizationWelcomeEmail',
};

module.exports = {
  iterableAPIFields,
  iterablePatientFields,
  iterableProviderFields,
  iterableIHAdminFields,
  iterableOrgAdminFields,
  eventNames,
};
